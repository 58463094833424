<template>
  <v-card color="primary" class="mx-auto" max-width="344">
    <v-img :src="require(`@/assets/${name}.${extension}`)" height="200px"></v-img>

    <v-card-title>{{ t(`${name}.title`) }}</v-card-title>

    <v-card-subtitle align="left">{{ t(`${name}.description`) }}</v-card-subtitle>

    <v-card-actions>
      <v-btn @click="share()" text>{{ t('share') }}</v-btn>

      <v-btn v-if="linkEmail" link color="blue" :href="'mailto:' + linkEmail" text>{{ t('mail_us') }}</v-btn>
      <v-btn v-else @click="join()" color="blue" text>{{ t('join') }}</v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text class="pt-0" align="left">{{ t(`${name}.extendedDescription`) }}</v-card-text>
      </div>
    </v-expand-transition>
    <v-dialog class="dialog-share" v-model="dialog" max-width="590">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Invitation Link</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="p-5 share-field bg-dark">
        <v-text-field ref="textToCopy" v-model="linkServer" :label="`${name} server`" readonly shaped></v-text-field>
      </div>
      <v-btn @click="copyText()" :dark="true">{{ t(`copy`) }}</v-btn>
    </v-dialog>
  </v-card>
</template>

<script>
import alertS from '@/services/AlertService/AlertService';

export default {
  props: {
    name: String,
    linkServer: String,
    linkEmail: String,
    extension: String,
  },
  data() {
    return {
      dialog: false,
      show: false,
    };
  },
  methods: {
    join() {
      if (!this.linkServer) return alertS.info(this.t('notAvailable'));
      window.open(this.linkServer);
    },
    share() {
      if (!this.linkServer) return alertS.info(this.t('notAvailable'));
      this.dialog = true;
    },
    t(text) {
      return this.$vuetify.lang.t(`$vuetify.Contact.${text}`);
    },
    copyText() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector('input');
      textToCopy.select();
      document.execCommand('copy');
    },
  },
};
</script>

<style lang="scss"></style>
